<template>
  <!-- from https://epic-spinners.epicmax.co/ -->
  <div>
    <div
      v-if="is('line-loader')"
      class="line-loader"
    >
      <div class="loader__element"></div>
    </div>

    <div
      v-if="is('looping-rhombuses-spinner')"
      class="looping-rhombuses-spinner"
    >
      <div class="rhombus"></div>
      <div class="rhombus"></div>
      <div class="rhombus"></div>
    </div>

    <div
      v-if="is('self-building-square-spinner')"
      class="self-building-square-spinner"
    >
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
      <div class="square clear"></div>
      <div class="square"></div>
      <div class="square"></div>
      <div class="square clear"></div>
      <div class="square"></div>
      <div class="square"></div>
    </div>

    <div
      v-if="is('circles-to-rhombuses-spinner')"
      class="circles-to-rhombuses-spinner"
    >
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>

    <div
      v-if="is('scaling-squares-spinner')"
      class="scaling-squares-spinner"
    >
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
      <div class="square"></div>
    </div>

    <div
      v-if="is('hollow-dots-spinner')"
      class="hollow-dots-spinner"
    >
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {

  },
  data () {
    return {
    }
  },
  methods: {
    is (name) {
      return this.animationName === name
    }
  },
  props: {
    animationName: String
  }
}
</script>

<style lang="stylus" scoped>
@css {
  .loader__element {
    background: var(--bg-plain) !important;

  }

  .loader__element:before,
.looping-rhombuses-spinner .rhombus,
.self-building-square-spinner .square
{
    background: var(--primary-color) !important;
  }

  .circles-to-rhombuses-spinner .circle
   {
    border: 3px solid var(--primary-color) !important;
  }
  .scaling-squares-spinner .square
  {
    border: calc((65px * 0.04 / 1.3)) solid var(--primary-color) !important;
  }

  .hollow-dots-spinner .dot
   {
    border: calc((15px / 5)) solid var(--primary-color) !important;
  }
}

.loader
  overflow hidden
  width 100%
  height 100%
  position fixed
  top 0
  left 0
  display flex
  align-items center
  align-content center
  justify-content flex-start
  z-index 100000

.loader__element
  height 2px
  width 100%
  background orange

.loader__element:before
  content ''
  display block
  background red
  height 2px
  width 0
  animation getWidth 3s ease-in infinite

@keyframes getWidth
  100%
    width 100%

.looping-rhombuses-spinner, .looping-rhombuses-spinner *
  box-sizing border-box

.looping-rhombuses-spinner
  width calc(15px * 4)
  height 15px
  position relative

.looping-rhombuses-spinner .rhombus
  height 15px
  width 15px
  background #ff1d5e
  left calc(15px * 4)
  position absolute
  margin 0 auto
  border-radius 2px
  transform translateY(0) rotate(45deg) scale(0)
  animation looping-rhombuses-spinner-animation 2500ms linear infinite

.looping-rhombuses-spinner .rhombus:nth-child(1)
  animation-delay calc((2500ms * 1 / -1.5))

.looping-rhombuses-spinner .rhombus:nth-child(2)
  animation-delay calc((2500ms * 2 / -1.5))

.looping-rhombuses-spinner .rhombus:nth-child(3)
  animation-delay calc((2500ms * 3 / -1.5))

@keyframes looping-rhombuses-spinner-animation
  0%
    transform translateX(0) rotate(45deg) scale(0)

  50%
    transform translateX(-233%) rotate(45deg) scale(1)

  100%
    transform translateX(-466%) rotate(45deg) scale(0)

.self-building-square-spinner, .self-building-square-spinner *
  box-sizing border-box

.self-building-square-spinner
  height 40px
  width 40px
  top calc((-10px * 2 / 3))

.self-building-square-spinner .square
  height 10px
  width 10px
  top calc((-10px * 2 / 3))
  margin-right calc((10px / 3))
  margin-top calc((10px / 3))
  background #ff1d5e
  float left
  position relative
  opacity 0
  animation self-building-square-spinner 6s infinite

.self-building-square-spinner .square:nth-child(1)
  animation-delay calc(300ms * 6)

.self-building-square-spinner .square:nth-child(2)
  animation-delay calc(300ms * 7)

.self-building-square-spinner .square:nth-child(3)
  animation-delay calc(300ms * 8)

.self-building-square-spinner .square:nth-child(4)
  animation-delay calc(300ms * 3)

.self-building-square-spinner .square:nth-child(5)
  animation-delay calc(300ms * 4)

.self-building-square-spinner .square:nth-child(6)
  animation-delay calc(300ms * 5)

.self-building-square-spinner .square:nth-child(7)
  animation-delay calc(300ms * 0)

.self-building-square-spinner .square:nth-child(8)
  animation-delay calc(300ms * 1)

.self-building-square-spinner .square:nth-child(9)
  animation-delay calc(300ms * 2)

.self-building-square-spinner .clear
  clear both

@keyframes self-building-square-spinner
  0%
    opacity 0

  5%
    opacity 1
    top 0

  50.9%
    opacity 1
    top 0

  55.9%
    opacity 0
    top inherit

.circles-to-rhombuses-spinner, .circles-to-rhombuses-spinner *
  box-sizing border-box

.circles-to-rhombuses-spinner
  height 15px
  width calc((15px + 15px * 1.125) * 3)
  display flex
  align-items center
  justify-content center

.circles-to-rhombuses-spinner .circle
  height 15px
  width 15px
  margin-left calc(15px * 1.125)
  transform rotate(45deg)
  border-radius 10%
  border 3px solid #ff1d5e
  overflow hidden
  background transparent
  animation circles-to-rhombuses-animation 1200ms linear infinite

.circles-to-rhombuses-spinner .circle:nth-child(1)
  animation-delay calc(150ms * 1)
  margin-left 0

.circles-to-rhombuses-spinner .circle:nth-child(2)
  animation-delay calc(150ms * 2)

.circles-to-rhombuses-spinner .circle:nth-child(3)
  animation-delay calc(150ms * 3)

@keyframes circles-to-rhombuses-animation
  0%
    border-radius 10%

  17.5%
    border-radius 10%

  50%
    border-radius 100%

  93.5%
    border-radius 10%

  100%
    border-radius 10%

@keyframes circles-to-rhombuses-background-animation
  50%
    opacity 0.4

.scaling-squares-spinner, .scaling-squares-spinner *
  box-sizing border-box

.scaling-squares-spinner
  height 65px
  width 65px
  position relative
  display flex
  flex-direction row
  align-items center
  justify-content center
  animation scaling-squares-animation 1250ms
  animation-iteration-count infinite
  transform rotate(0deg)

.scaling-squares-spinner .square
  height calc((65px * 0.25 / 1.3))
  width calc((65px * 0.25 / 1.3))
  margin-right auto
  margin-left auto
  border calc((65px * 0.04 / 1.3)) solid #ff1d5e
  position absolute
  animation-duration 1250ms
  animation-iteration-count infinite

.scaling-squares-spinner .square:nth-child(1)
  animation-name scaling-squares-spinner-animation-child-1

.scaling-squares-spinner .square:nth-child(2)
  animation-name scaling-squares-spinner-animation-child-2

.scaling-squares-spinner .square:nth-child(3)
  animation-name scaling-squares-spinner-animation-child-3

.scaling-squares-spinner .square:nth-child(4)
  animation-name scaling-squares-spinner-animation-child-4

@keyframes scaling-squares-animation
  50%
    transform rotate(90deg)

  100%
    transform rotate(180deg)

@keyframes scaling-squares-spinner-animation-child-1
  50%
    transform translate(150%, 150%) scale(2, 2)

@keyframes scaling-squares-spinner-animation-child-2
  50%
    transform translate(-150%, 150%) scale(2, 2)

@keyframes scaling-squares-spinner-animation-child-3
  50%
    transform translate(-150%, -150%) scale(2, 2)

@keyframes scaling-squares-spinner-animation-child-4
  50%
    transform translate(150%, -150%) scale(2, 2)

.hollow-dots-spinner, .hollow-dots-spinner *
  box-sizing border-box

.hollow-dots-spinner
  height 15px
  width calc(30px * 3)

.hollow-dots-spinner .dot
  width 15px
  height 15px
  margin 0 calc((15px / 2))
  border calc((15px / 5)) solid #ff1d5e
  border-radius 50%
  float left
  transform scale(0)
  animation hollow-dots-spinner-animation 1000ms ease infinite 0ms

.hollow-dots-spinner .dot:nth-child(1)
  animation-delay calc(300ms * 1)

.hollow-dots-spinner .dot:nth-child(2)
  animation-delay calc(300ms * 2)

.hollow-dots-spinner .dot:nth-child(3)
  animation-delay calc(300ms * 3)

@keyframes hollow-dots-spinner-animation
  50%
    transform scale(1)
    opacity 1

  100%
    opacity 0
</style>
